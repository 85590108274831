import { getData } from '@services/request';

// 查询pointId
export async function queryPointId ({ monitorId, metricCodes }) {
  let fres = [];
  const res = await getData('assetManagement', 'queryPointByMonitoredObjectIdsAndMetricCodes', { monitoredObjectIds: monitorId, metricCodes });

  if (res.code === null) {
    fres = (res.dataList || []).reduce((acc, item) => {
      acc[0][item.pointId] = item;
      acc[1].push(item.pointId);
      acc[2][item.metricCode] = item;
      return acc;
    }, [{}, [], {}]);
  }

  return [fres, res];
}
export async function queryPointId1 ({ monitorId, metricCodes }) {
  let fres = [];
  const res = await getData('metric', 'queryPointByMonitoredObjectIdsAndMetricCodes', { monitoredObjectIds: monitorId, metricCodes });

  if (res.code === null) {
    fres = (res.dataList || []).reduce((acc, item) => {
      acc[0][item.pointId] = item;
      acc[1].push(item.pointId);
      acc[2][item.metricCode] = item;
      return acc;
    }, [{}, [], {}]);
  }

  return [fres, res];
}

// 查询电芯的pointid
export async function queryPointIdDx ({ monitorId, location }) {
  let fres = [];
  const res = await getData('metric', 'queryPointByMonitoredObjectIdAndLocation', { monitoredObjectId: monitorId, location });
  if (res.code === null) {
    fres = (res.dataList || []).reduce((acc, item) => {
      acc[0][item.pointId] = item;
      acc[1].push(item.pointId);
      acc[2][item.metricCode] = item;
      return acc;
    }, [{}, [], {}]);
  }

  return [fres, res];
}

// 根据pointId, startTime, endTime 查数据
export async function queryRawMetricDatum ({ pointIds, startTime, endTime, replaceRequestUrl,pointIdsHash }) {
  let fres = '';
  if (!pointIds) return;
  const res = await getData(replaceRequestUrl ? replaceRequestUrl : 'metricDatum/queryMetricDatum', '', {
    pointIds: JSON.stringify(pointIds.split(',')),
    startTime,
    endTime,
    offset: 0,
    limit: -1,
    aggregator: JSON.stringify({
      interval: 1,
      unit: 'm',
      fillPolicy: 'previous',
    }),
  });

  if (res.code === null) {
    fres = res.dataList || '';
    let totalPowerData = fres?.find(item=> pointIdsHash?.[item.id]?.metricCode=== "C4601C44") || null;
    // soc不需要处理
    let totalSocData =  fres?.find(item=> pointIdsHash?.[item.id]?.metricCode=== "02110102") || null;
    totalPowerData && fres.forEach(item=>{
      if(item.id!==totalPowerData?.id){
        item.dataList.forEach((_item,_index)=>{
           const matchValue = totalPowerData.dataList.find(list=> list.t ===_item.t);  //匹配到时间点
           const initValue = matchValue?.v ? matchValue.v  : 0;
           const boolenValue = initValue ? Number(initValue): 0
            _item.v =  totalSocData?.id===item.id ? _item.v:(_item.v? Number(_item.v):0) * (boolenValue ? -1: 1) || ''
        }) 
      }
    })

    if(totalPowerData){
      fres = fres.filter(item=> item.id!==totalPowerData.id);
      res.dataList = res.dataList.filter(item=> item.id!==totalPowerData.id);
    }

  }

  return [fres, res];
}

// 根据pointId查数据
export async function queryLatestMetricDatum ({ pointIds }) {
  let fres = [];
  if (!pointIds) return;
  const res = await getData('metricDatum', 'queryLatestMetricDatum', { pointIds });

  if (res.code === null) {
    fres = res.dataList || [];
  }

  return [fres, res];
}

// 根据设备id和metriccode 查实时数据
export async function queryRealData ({ id, metriccodes, flag }) {
  let fres = {};
  let res;
  if (flag) {
    res = await getData('storedEnergy', 'querylastMetricDataByObjectId', { batteryGroupId: id });
  } else {
    res = await getData('businessCustom', 'getLastDataByObjectIdAndSignal', { objectId: id, signal: metriccodes });
  }

  if (res.code === null) {
    fres = (res.dataList || []).reduce((acc, item) => {
      acc[item.metricCode] = item;
      return acc;
    }, {});
  }
  return fres;
}

// 根据id查询展示的数据项
export async function queryDataItem ({ ids, stationId, deviceType }) {
  let fres = [{}, {}];
  const res = await getData('storedEnergy', 'queryPointByMonitoredObjectIds', { monitoredObjectIds: ids, stationId, deviceType });

  if (res.code === null) {
    fres = res.dataList && res.dataList[0] && res.dataList[0].reduce((acc, item) => {
      acc[0][item.metricCode] = item;
      acc[1][item.pointId] = item;
      return acc;
    }, [{}, {}]);
  }
  return [fres[0], fres[1], res];
}

// 通过服务商id获取企业
export async function queryCompanyList ({ serviceProviderId }) {
  let fres = [];
  const res = await getData('storedEnergy', 'getEnterPriseListByServiceProviderId  ', { serviceProviderId });

  if (res.code === null) {
    fres = (res.dataList || []).map((item) => {
      return {
        key: item.customerId,
        value: item.name,
        ...item,
      };
    });
  }

  return [fres, res];
}

// 电站总览 - 查询列表
export async function queryList ({ stationIds, name, enterpriseId, status, page, length }) {
  let fres = [];
  let totalCount = 0;
  const res = await getData('storedEnergy', 'pageQueryStationsListByEnterpriseId', { stationIds, stationName: name, enterpriseId, stationStatus: status, page, length });

  if (res.code === null) {
    fres = (res.dataList || []).map((item, idx) => {
      return {
        ...item,
        key: item.id,
        id: item.id,
        stationId: item.id,
        rowNum: idx + 1,
        shortName: item.stationName,
        status: item.stationStatus,
        installPower: item.installedPower && (+item.installedPower).toFixed(2),
        installCapacity: item.capacity && (+item.capacity).toFixed(2),
        realPower: item.power && (+item.power / 1000).toFixed(2),
        yesterdayCharge: item.charge && (+item.charge / 1000).toFixed(2),
        yesterdayDischarge: item.discharge && (+item.discharge / 1000).toFixed(2),
        averageEfficiency: item.averageEfficiency && (+item.averageEfficiency).toFixed(1),
        alarmNum: item.alarmCount,
        address: item.address,
        enterpriseName: item.enterpriseId,
      };
    });
    totalCount = res.totalCount;
  }

  return [fres, totalCount, res];
}

// 电站总览 - 根据站点id查询站点信息
export async function queryStationInfo ({ stationId }) {
  let fres = {};
  const res = await getData('storedEnergy', 'getStationInfoById', { stationId });

  if (res.code === null|| res.code==="200") {
    fres = {
      ...res.data,
      shortName: res.data.stationName,
      logo: res.data.img,
      address: res.data.address,
      longitude: res.data.longitude,
      latitude: res.data.latitude,
      enterpriseId: res.data.enterpriseId,
      linkman: res.data.contactPerson,
      phone: res.data.phone,
      installPower: res.data.installedPower,
      capacity: res.data.capacity,
      voltageLevel: res.data.voltageLevel,
      runStrategy: res.data.runStrategy,
      connectTime: res.data.launchDate,
    };
  }
  return [fres, res];
}

// 根据站点id查询站点状态
export async function queryStationStatus ({ stationIds }) {
  let fres = [];
  const res = await getData('storedEnergy', 'queryStationStatus', { stationIds });

  if (res.code === null) {
    fres = res.dataList || [];
  }
  return [fres, res];
}

// 根据站点id 查询运行策略
export async function queryRunStrategy ({ stationId }) {
  let fres = [];
  const res = await getData('storedEnergy', 'queryChargeDischargeStrategyOverviewByStationId', { stationId });

  if (res.code === null) {
    fres = res.dataList || [];
  }
  return [fres, res];
}

// 电站总览 - 查询电站告警信息
export async function queryStationAlarmInfo ({ stationId }) {
  const fres = {};
  const resToday = await getData('storedEnergy', 'queryNewAlarmStationCountByStationIds', {
    stationIds: JSON.stringify([stationId]),
    startTime: moment().startOf('day').valueOf(),
    endTime: moment().endOf('day').valueOf(),
  });
  const resYesterday = await getData('storedEnergy', 'queryNewAlarmStationCountByStationIds', {
    stationIds: JSON.stringify([stationId]),
    startTime: moment().subtract(1, 'days').startOf('day').valueOf(),
    endTime: moment().subtract(1, 'days').endOf('day').valueOf(),
  });
  const resTodayHandle = await getData('storedEnergy', 'queryDealAlarmStationCountByStationIds', {
    stationIds: JSON.stringify([stationId]),
    startTime: moment().startOf('day').valueOf(),
    endTime: moment().endOf('day').valueOf(),
  });
  const resNotHandle = await getData('storedEnergy', 'queryUnfinishAlarmStationCountByStationIds', { stationIds: JSON.stringify([stationId]) });

  if (resToday.code === null) {
    fres.todayAdd = resToday.dataList[0] && resToday.dataList[0].count;
  }
  if (resYesterday.code === null) {
    fres.yesterdayAdd = resYesterday.dataList[0] && resYesterday.dataList[0].count;
  }
  if (resTodayHandle.code === null) {
    fres.todayHandle = resTodayHandle.dataList[0] && resTodayHandle.dataList[0].count;
  }
  if (resNotHandle.code === null) {
    fres.nowNohandle = resNotHandle.dataList[0] && resNotHandle.dataList[0].count;
  }
  return [fres];
}

// 电站总览 - 查询该站的可充放电量
export async function queryStationChargeAndUncharge ({ stationId }) {
  let fres = {};
  const metricCodeArr = [
    '02110100', // 累计充电量
    '02110101', // 累计放电量
    '0211013D', // 可充电量
    '0211013E', // 可放电量
  ];
  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: stationId, metricCodes: JSON.stringify(metricCodeArr) });
  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryLatestMetricDatum({ pointIds: pointIdsMap.join(',') });
    const dataHash = data.reduce((acc, item) => {
      if (item) {
        acc[pointIdsHash[item.pointId].metricCode] = {
          ...(item || {}),
          ...pointIdsHash[item.pointId],
        };
      }
      return acc;
    }, {});

    fres = {
      charge: dataHash[metricCodeArr[0]] ? (dataHash[metricCodeArr[0]].value / 1000).toFixed(2) : '',
      uncharge: dataHash[metricCodeArr[1]] ? (dataHash[metricCodeArr[1]].value / 1000).toFixed(2) : '',
      canCharge: dataHash[metricCodeArr[2]] ? (dataHash[metricCodeArr[2]].value / 1000).toFixed(2) : '',
      canUnCharge: dataHash[metricCodeArr[3]] ? (dataHash[metricCodeArr[3]].value / 1000).toFixed(2) : '',
    };
  }
  return [fres];
}

// 电站总览 - 查询该电站下级设备
export async function queryLowLevelList ({ stationId }) {
  let fres = [];
  const res = await getData('storedEnergy', 'querySonNodeByParentDeviceId', { parentDeviceId: stationId });

  if (res.code === null) {
    fres = res.dataList.map((item) => {
      return {
        displayType: item.displayType,
        id: item.displayId,
        name: item.displayName,
      };
    });
  }
  return [fres, res];
}

// 查询(实时有功,无功, 直流电压, 直流电流, 直流功率, soc, 电池室温度)
export async function getRealData ({ idNames }) {
  let fres = [];
  const metricCodeArr = [
    '02030000', // 有功
    '02040000', // 无功
    '020D5009', // 直流电压
    '020D500A', // 直流电流
    '020D500B', // 直流功率
    '02110102', // soc
    '0211012B', // 电池室温度
    '0211013E', // 可放电量
  ];
  const res = await getData('businessCustom', 'getLastData', { objectId: idNames.map(item => item.id).join(','), signal: metricCodeArr.join(',') });
  const { data: status } = await getData('storedEnergy', 'queryPcsOrBranchStatusByDeviceIdList', { deviceIds: JSON.stringify(idNames.map(item => item.id)) });

  const keyArr = idNames.map((item) => {
    return {
      displayType: item.type,
      key: item.id,
      id: item.id,
      name: item.name,
      data: metricCodeArr.map((item1) => {
        return item1 + item.id;
      }),
    };
  });

  if (res.code === null) {
    fres = keyArr.map((item) => {
      return {
        ...item,
        status: status[item.id],
        realPower: res.data[item.data[0]] && res.data[item.data[0]].value,
        realNopower: res.data[item.data[1]] && res.data[item.data[1]].value,
        zldy: res.data[item.data[2]] && res.data[item.data[2]].value,
        zldl: res.data[item.data[3]] && res.data[item.data[3]].value,
        zlgl: res.data[item.data[4]] && res.data[item.data[4]].value,
        soc: res.data[item.data[5]] && res.data[item.data[5]].value,
        temperature: res.data[item.data[6]] && res.data[item.data[6]].value && (+res.data[item.data[6]].value).toFixed(1),
        kfdl: res.data[item.data[7]] && res.data[item.data[7]].value && (+res.data[item.data[7]].value / 1000).toFixed(2),
      };
    });
  }
  return [fres, res];
}


// 电站总览 - 图表数据
export async function queryChartData ({ startTime, endTime, metric, monitorId }) {
  let fres = {};
  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: JSON.stringify(monitorId), metricCodes: JSON.stringify(metric) });

  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryRawMetricDatum({ pointIds: pointIdsMap.join(','), startTime, endTime,pointIdsHash });

    fres = data.reduce((acc, item) => {
      acc[pointIdsHash[item.id].metricCode] = (item.dataList || []).map((item) => {
        return { value: [+item.t, (+item.v).toFixed(2)] };
      });
      return acc;
    }, {});
  }

  return [fres];
}
export async function queryChartData1 ({ startTime, endTime, metric, monitorId }) {
  let fres = [];
  const [[pointIdsHash, pointIdsMap]] = await queryPointId1({ monitorId: JSON.stringify(monitorId), metricCodes: JSON.stringify(metric) });

  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryRawMetricDatum({ pointIds: pointIdsMap.join(','), startTime, endTime,pointIdsHash });

    fres = data.reduce((acc, item) => {
      acc.push({
        ...pointIdsHash[item.id],
        dataList: (item.dataList || []).map((item) => {
          return { value: [+item.t, (+item.v).toFixed(2)] };
        }),
      });

      return acc;
    }, []);
  }

  return [fres];
}

// 数据监测 - 充放电量 - 峰时电量
export async function queryJfpgCfdlData ({ startTime, endTime, metric, monitorId }) {
  let fres = {};
  const res = await getData('storedEnergy', 'queryElectricityAndElectricityCharges', { startTime, endTime, metricCodes: metric.join(','), monitoredObjectIds: JSON.stringify(monitorId) });


  if (res.code === null) {
    fres = Object.keys(res.data).reduce((acc, metr) => {
      acc[metr] = Object.keys(res.data[metr]).reduce((acc1, time) => {
        acc1.push({ value: [time, res.data[metr][time].total], jfpg: res.data[metr][time] || {} });
        return acc1;
      }, []);
      return acc;
    }, {});
  }
  return [fres];
}

// 数据监测 - 树数据
export async function queryTreeData ({ monitorId }) {
  const fres = [[], {}, {}];
  const res = await getData('storedEnergy', 'getAssetTreeByStationId', { stationId: monitorId });
  // 操作平级树结构,成为标准树结构
  const optTreeData = (sourceTreeData, item) => {
    const filterData = sourceTreeData.filter((d) => { return d.parentId === item.id; });
    if (filterData.length > 0) {
      item.children = filterData;
      filterData.forEach((d) => {
        optTreeData(sourceTreeData, d);
      });
    }
  };

  if (res.code === null) {
    const treeData = (res.dataList || []).reduce((acc, item) => {
      acc.push({
        ...item,
        key: item.id,
        id: item.id,
        title: item.displayName,
        type: item.displayType,
        pid: item.parentId,
      });
      return acc;
    }, []);

    fres[0] = treeData.filter((item) => { return item.parentId === treeData.filter(itm => itm.parentId === null)[0].id; });  // 首先得到第一层树结构
    fres[0].forEach((item) => {
      optTreeData(treeData, item);
    });
    fres[1] = treeData.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    fres[2] = treeData.reduce((acc, item) => {
      acc[item.displayId] = item;
      return acc;
    }, {});
  }
  return [fres, res];
}

// 数据监测-新增-变压器信息
export async function queryByqInfo ({ monitorId }) {
  let fres = {};
  const metricCodes = [
    '02030000', // 有功功率0
    '02040000', // 无功功率1

    '02010100', // a相电压2
    '02010200', // a相电压3
    '02010300', // a相电压4
    '02010400', // ab相电压5
    '02010500', // bc相电压6
    '02010600', // ca相电压7

    '02020100', // a相电流8
    '02020200', // b相电流9
    '02020300', // c相电流10
  ];
  const res = await getData('storedEnergy', 'queryDeviceStatAndBaseInfoByIdAndType', { id: monitorId, type: 40003, metricCodeList: JSON.stringify(metricCodes) });

  if (res.code === null) {
    const resData = res.data || {};
    let dl = '';
    let dy = '';
    if (!(resData[metricCodes[5]] || resData[metricCodes[6]] || resData[metricCodes[7]])) {
      if (resData[metricCodes[2]] || resData[metricCodes[3]] || resData[metricCodes[4]]) {
        dy = `${resData[metricCodes[2]] ? (+resData[metricCodes[2]]).toFixed(2) : '--'}/${resData[metricCodes[3]] ? (+resData[metricCodes[3]]).toFixed(2) : '--'}/${resData[metricCodes[4]] ? (+resData[metricCodes[4]]).toFixed(2) : '--'}`;
      }
    } else {
      dy = `${resData[metricCodes[5]] ? (+resData[metricCodes[5]]).toFixed(2) : '--'}/${resData[metricCodes[6]] ? (+resData[metricCodes[6]]).toFixed(2) : '--'}/${resData[metricCodes[7]] ? (+resData[metricCodes[7]]).toFixed(2) : '--'}`;
    }

    if (resData[metricCodes[8]] || resData[metricCodes[9]] || resData[metricCodes[10]]) {
      dl = `${resData[metricCodes[8]] ? (+resData[metricCodes[8]]).toFixed(2) : '--'}/${resData[metricCodes[9]] ? (+resData[metricCodes[9]]).toFixed(2) : '--'}/${resData[metricCodes[10]] ? (+resData[metricCodes[10]]).toFixed(2) : '--'}`;
    }
    fres = {
      capacity: resData.capacity,
      transformationRatio: resData.transformationRatio,
      yggl: resData[metricCodes[0]] && (+resData[metricCodes[0]]).toFixed(2),
      wggl: resData[metricCodes[1]] && (+resData[metricCodes[1]]).toFixed(2),
      dy,
      dl,
    };
  }
  return [fres, res];
}

// 数据监测-新增-并网点信息
export async function queryBwdInfo ({ monitorId }) {
  let fres = {};
  const metricCodes = [
    '0211013D', // 可充电量
    '0211013E', // 可放电量
    '02110100', // 采集的累计充电量，即正向有功总电能示值
    '02110101', // 采集的累计放电量，即反向有功总电能示值
  ];
  const res = await getData('storedEnergy', 'queryDeviceStatAndBaseInfoByIdAndType', { id: monitorId, type: 40027, metricCodeList: JSON.stringify(metricCodes) });

  if (res.code === null) {
    const resData = res.data || {};
    fres = {
      voltageClass: resData.voltageClass,
      kcdl: resData[metricCodes[0]] && (+resData[metricCodes[0]]).toFixed(2),
      kfdl: resData[metricCodes[1]] && (+resData[metricCodes[1]]).toFixed(2),
      ljcdl: resData[metricCodes[2]] && (+resData[metricCodes[2]] / 1000).toFixed(2),
      ljfdl: resData[metricCodes[3]] && (+resData[metricCodes[3]] / 1000).toFixed(2),
    };
  }
  return [fres, res];
}

// 数据监测 - 储能室信息
export async function queryStorageRoomInfo ({ monitorId }) {
  let fres = [];
  const metricCodes = [
    // '00010000', // 采集的累计充电量，即正向有功总电能示值
    // '00020000', // 采集的累计放电量，即反向有功总电能示值
    '02110100', // 累计放电量
    '02110101', // 累计放电量
    '0211013D', // 可充电量
    '0211013E', // 可放电量
    '0211012B', // 电池室温度
    '0211012D', // 电气室温度
  ];
  const res = await getData('storedEnergy', 'getEnergyStorageRoomInfoById', { energyStorageRoomId: monitorId });
  const realData = await queryRealData({ id: monitorId, metriccodes: metricCodes.join(',') });

  if (res.code === null) {
    fres = {
      ...res.data,
      charge: realData[metricCodes[0]] && (+realData[metricCodes[0]].value / 1000).toFixed(2),
      unCharge: realData[metricCodes[1]] && (+realData[metricCodes[1]].value / 1000).toFixed(2),
      canCharge: realData[metricCodes[2]] && (+realData[metricCodes[2]].value / 1000).toFixed(2),
      canUnCharge: realData[metricCodes[3]] && (+realData[metricCodes[3]].value / 1000).toFixed(2),
      dcsTemperature: realData[metricCodes[4]] && (+realData[metricCodes[4]].value).toFixed(1),
      dqsTemperature: realData[metricCodes[5]] && (+realData[metricCodes[5]].value).toFixed(1),
      capacity: res.data.capacity ? (res.data.capacity / 1000).toFixed(2) : res.data.capacity,
    };
  }
  return [fres, res];
}

// 数据监测 - 储能室信息 - 一些状态
export async function queryStorageRoomStatus ({ storageRoomId }) {
  let fres = {};
  const res = await getData('storedEnergy', 'queryDeviceStateByStorageRoomId', { storageRoomId });

  if (res.code === null) {
    fres = res.data || {};
  }
  return [fres, res];
}

// 数据监测 - 逆变器信息
export async function queryPcsInfo ({ monitorId }) {
  let fres = [];
  const metricCodes = [
    '00010000', // 累计充电量
    '00020000', // 累计放电量
  ];
  const res = await getData('storedEnergy', 'getPcsInfoById', { pcsId: monitorId });
  const realData = await queryRealData({ id: monitorId, metriccodes: metricCodes.join(',') });
  const { data: status } = await getData('storedEnergy', 'queryPcsOrBranchStatus', { deviceId: monitorId });

  if (res.code === null) {
    fres = {
      ...res.data,
      charge: realData[metricCodes[0]] && (+realData[metricCodes[0]].value / 1000).toFixed(2),
      unCharge: realData[metricCodes[1]] && (+realData[metricCodes[1]].value / 1000).toFixed(2),
      status,
    };
  }
  return [fres, res];
}

// 数据监测 - 支路信息
export async function queryBranchInfo ({ monitorId, batteryGroupIds }) {
  let fres = [];
  const res = await getData('storedEnergy', 'getBranchInfoById', { branchId: monitorId });
  const resNum = await getData('storedEnergy', 'queryBatteryCellNumberByBatteryGroupIds', { batteryGroupIds });
  const { data: status } = await getData('storedEnergy', 'queryPcsOrBranchStatus', { deviceId: monitorId });
  const number = Object.keys(resNum.data || {}).reduce((acc, batteryId) => {
    acc += resNum.data[batteryId];
    return acc;
  }, 0);

  if (res.code === null) {
    fres = {
      ...res.data,
      number,
      status,
    };
  }
  return [fres, res];
}

// 数据监测 - 电池组信息
export async function queryBatteryGroupInfo ({ monitorId, voltageCellNumber, temperatureCellNumber, batteryGroupItemOrder }) {
  let fres = {};
  const metricCodes = [
    '020D5009', // 电池组直流电压
    '0211011A', // 单体最高电压
    '0211011B', // 单体最高压编号
    '0211011C', // 单体最低电压
    '0211011D', // 单体最低压编号
    '020D500B', // 电池组直流功率
    '02110106', // 电池组温度
    '02110116', // 单体最高温度
    '02110117', // 单体最高温度编号
    '02110118', // 单体最低温度
    '02110119', // 单体最低温度编号
    '020D500A', // 电池组直流电流
    '02110102', // 电池组soc
    '0211011E', // 单体最高soc
    '0211011F', // 单体最高soc编号
    '02110120', // 单体最低soc
    '02110121', // 单体最低soc编号
    '02110103', // soh
    '0211012A', // 绝缘阻值
    '02110122', // 单体最高内阻
    '02110123', // 单体最高内阻编号
    '02110124', // 单体最低内阻
    '02110125', // 单体最低内阻编号
  ];
  const realData = await queryRealData({ id: monitorId, flag: true });
  let highVoltageNum = realData[metricCodes[2]] && +realData[metricCodes[2]].value;
  let lowVoltageNum = realData[metricCodes[4]] && +realData[metricCodes[4]].value;
  let highTemperatureNum = realData[metricCodes[8]] && +realData[metricCodes[8]].value;
  let lowTemperatureNum = realData[metricCodes[10]] && +realData[metricCodes[10]].value;
  let highSocNum = realData[metricCodes[14]] && +realData[metricCodes[14]].value;
  let lowSocNum = realData[metricCodes[16]] && +realData[metricCodes[16]].value;
  let highHinderNum = realData[metricCodes[20]] && +realData[metricCodes[20]].value;
  let lowHinderNum = realData[metricCodes[22]] && +realData[metricCodes[22]].value;

  highVoltageNum = highVoltageNum && `${Math.ceil((batteryGroupItemOrder.indexOf(highVoltageNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(highVoltageNum) % voltageCellNumber) + 1}`;

  lowVoltageNum = lowVoltageNum && `${Math.ceil((batteryGroupItemOrder.indexOf(lowVoltageNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(lowVoltageNum) % voltageCellNumber) + 1}`;
  highTemperatureNum = highTemperatureNum && `${Math.ceil((batteryGroupItemOrder.indexOf(highTemperatureNum) + 1) / temperatureCellNumber)}-${(batteryGroupItemOrder.indexOf(highTemperatureNum) % temperatureCellNumber) + 1}`;
  lowTemperatureNum = lowTemperatureNum && `${Math.ceil((batteryGroupItemOrder.indexOf(lowTemperatureNum) + 1) / temperatureCellNumber)}-${(batteryGroupItemOrder.indexOf(lowTemperatureNum) % temperatureCellNumber) + 1}`;
  highSocNum = highSocNum && `${Math.ceil((batteryGroupItemOrder.indexOf(highSocNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(highSocNum) % voltageCellNumber) + 1}`;
  lowSocNum = lowSocNum && `${Math.ceil((batteryGroupItemOrder.indexOf(lowSocNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(lowSocNum) % voltageCellNumber) + 1}`;
  highHinderNum = highHinderNum && `${Math.ceil((batteryGroupItemOrder.indexOf(highHinderNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(highHinderNum) % voltageCellNumber) + 1}`;
  lowHinderNum = lowHinderNum && `${Math.ceil((batteryGroupItemOrder.indexOf(lowHinderNum) + 1) / voltageCellNumber)}-${(batteryGroupItemOrder.indexOf(lowHinderNum) % voltageCellNumber) + 1}`;
  // const resNum = await getData('storedEnergy', 'queryBatteryCellNumberByBatteryGroupIds', { batteryGroupIds: JSON.stringify([monitorId]) });
  // const number = Object.keys(resNum.data || {}).reduce((acc, batteryId) => {
  //   acc += resNum.data[batteryId];
  //   return acc;
  // }, 0);
  fres = {
    voltage: realData[metricCodes[0]] && realData[metricCodes[0]].value && (+realData[metricCodes[0]].value).toFixed(2),
    highVoltage: realData[metricCodes[1]] && realData[metricCodes[1]].value && (+realData[metricCodes[1]].value).toFixed(3),
    highVoltageNum,
    lowVoltage: realData[metricCodes[3]] && realData[metricCodes[3]].value && (+realData[metricCodes[3]].value).toFixed(3),
    lowVoltageNum,
    power: realData[metricCodes[5]] && realData[metricCodes[5]].value && (+realData[metricCodes[5]].value).toFixed(2),
    temperature: realData[metricCodes[6]] && realData[metricCodes[6]].value && (+realData[metricCodes[6]].value).toFixed(1),
    highTemperature: realData[metricCodes[7]] && realData[metricCodes[7]].value && (+realData[metricCodes[7]].value).toFixed(1),
    highTemperatureNum,
    lowTemperature: realData[metricCodes[9]] && realData[metricCodes[9]].value && (+realData[metricCodes[9]].value).toFixed(1),
    lowTemperatureNum,
    electricCurrent: realData[metricCodes[11]] && realData[metricCodes[11]].value && (+realData[metricCodes[11]].value).toFixed(2),
    soc: realData[metricCodes[12]] && realData[metricCodes[12]].value && (+realData[metricCodes[12]].value).toFixed(1),
    highSoc: realData[metricCodes[13]] && realData[metricCodes[13]].value && (+realData[metricCodes[13]].value).toFixed(1),
    highSocNum,
    lowSoc: realData[metricCodes[15]] && realData[metricCodes[15]].value && (+realData[metricCodes[15]].value).toFixed(1),
    lowSocNum,
    soh: realData[metricCodes[17]] && realData[metricCodes[17]].value && (+realData[metricCodes[17]].value).toFixed(1),
    hinder: realData[metricCodes[18]] && realData[metricCodes[18]].value && (+realData[metricCodes[18]].value).toFixed(2),
    highHinder: realData[metricCodes[19]] && realData[metricCodes[19]].value && (+realData[metricCodes[19]].value).toFixed(2),
    highHinderNum,
    lowHinder: realData[metricCodes[21]] && realData[metricCodes[21]].value && (+realData[metricCodes[21]].value).toFixed(2),
    lowHinderNum,
    // number: number.toString(),
  };
  return [fres];
}

// 数据监测 - 电池堆信息
export async function queryBatteryHeapInfo ({ monitorId }) {
  let fres = {};
  const metricCodes = [
    '02110102', // soc
    '0211011A', // 单体最高电压
    '0211011B', // 单体最高压编号
    '02110116', // 单体最高温度
    '02110117', // 单体最高温度编号
    '02110122', // 单体最高内阻
    '02110123', // 单体最高内阻编号
    '0211011E', // 单体最高soc
    '0211011F', // 单体最高soc编号
    '02110103', // soh
    '0211011C', // 单体最低电压
    '0211011D', // 单体最低压编号
    '02110118', // 单体最低温度
    '02110119', // 单体最低温度编号
    '02110124', // 单体最低内阻
    '02110125', // 单体最低内阻编号
    '02110120', // 单体最低soc
    '02110121', // 单体最低soc编号
  ];
  const realData = await queryRealData({ id: monitorId, metriccodes: metricCodes.join(',') });
  fres = {
    soc: realData[metricCodes[0]] && (+realData[metricCodes[0]].value).toFixed(1),
    highVoltage: realData[metricCodes[1]] && (+realData[metricCodes[1]].value).toFixed(2),
    highVoltageNum: realData[metricCodes[2]] && (+realData[metricCodes[2]].value).toFixed(0), // 几号电芯？
    highTemperature: realData[metricCodes[3]] && (+realData[metricCodes[3]].value).toFixed(1),
    highTemperatureNum: (realData[metricCodes[4]]) && (+realData[metricCodes[4]].value).toFixed(0),
    highHinder: realData[metricCodes[5]] && (+realData[metricCodes[5]].value).toFixed(2),
    highHinderNum: (realData[metricCodes[6]]) && (+realData[metricCodes[6]].value).toFixed(0),
    highSoc: realData[metricCodes[7]] && (+realData[metricCodes[7]].value).toFixed(1),
    highSocNum: (realData[metricCodes[8]]) && (+realData[metricCodes[8]].value).toFixed(0),
    soh: realData[metricCodes[9]] && (+realData[metricCodes[9]].value).toFixed(1),
    lowVoltage: realData[metricCodes[10]] && (+realData[metricCodes[10]].value).toFixed(2),
    lowVoltageNum: (realData[metricCodes[11]]) && (+realData[metricCodes[11]].value).toFixed(0),
    lowTemperature: realData[metricCodes[12]] && (+realData[metricCodes[12]].value).toFixed(1),
    lowTemperatureNum: (realData[metricCodes[13]]) && (+realData[metricCodes[13]].value).toFixed(0),
    lowHinder: realData[metricCodes[14]] && (+realData[metricCodes[14]].value).toFixed(2),
    lowHinderNum: (realData[metricCodes[15]]) && (+realData[metricCodes[15]].value).toFixed(0),
    lowSoc: realData[metricCodes[16]] && (+realData[metricCodes[16]].value).toFixed(1),
    lowSocNum: (realData[metricCodes[17]]) && (+realData[metricCodes[17]].value).toFixed(0),
  };
  return [fres];
}

// 数据监测 - 电池组查询列表
export async function querySubTableList ({ monitorId, keywords, page, length }) {
  let fres = [];
  let totalCount = 1;
  const res = await getData('storedEnergy', 'queryBatteryCellDataView', { batteryGroupId: monitorId, location: keywords, page, length });

  if (res.code === null) {
    fres = (res.dataList || []).map((item, index) => {
      return {
        key: index,
        id: index,
        name: item.location,
        voltage: item.voltage && item.voltage.toFixed(3),
        temperature: item.temperature && item.temperature.toFixed(2),
        resistance: item.resistance && item.resistance.toFixed(2),
        soc: item.soc && item.soc.toFixed(1),
        soh: item.soh && item.soh.toFixed(1),
      };
    });
    totalCount = res.totalCount;
  }

  return [fres, totalCount, res];
}

// 数据监测 - 电池组弹出框图表
export async function queryAlertChartData ({ startTime, endTime, location, monitorId, replaceRequestUrl }) {
  let fres = {};
  const [[pointIdsHash, pointIdsMap]] = await queryPointIdDx({ monitorId, location });

  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryRawMetricDatum({ pointIds: pointIdsMap.join(','), startTime, endTime, replaceRequestUrl,pointIdsHash });

    fres = data.reduce((acc, item) => {
      const metricCode = pointIdsHash[item.id].metricCode;
      acc[metricCode] = (item.dataList || []).map((item) => {
        // 02110130  为电芯电压为三条线，所以返回在了一个v字段里需要单独处理
        let value = ''
        if (metricCode === '02110130') {
          const str = item.v || '';
          const arr = str.split(',') || [];
          const finalResult = arr.map(_item => (+_item).toFixed(2));
          value = finalResult.join(',')
        } else {
          value = (+item.v).toFixed(metricCode === '02110111' ? 3 : 2)
        }
        return { value: [+item.t, value] };
      });
      return acc;
    }, {});
  }
  return [fres];
}

// 电芯报表导出
export async function exportData (param) {
  let fres = '';
  const res = await getData('businessCustom', 'exportBgAndBcStatVoAsExcel', { ...param });
  if (res.code === null) {
    fres = res.data;
  }

  return [fres];
}
// 电池堆报表导出
export async function exportDcdData (param) {
  let fres = '';
  const res = await getData('storedEnergy', 'exportBatteryHeapReportForm', { ...param });
  if (res.code === null) {
    fres = res.data;
  }

  return [fres];
}
// 逆变器导出
export async function exportNbqData (param) {
  let fres = '';
  const res = await getData('storedEnergy', 'exportPcsReportForm', { ...param });
  if (res.code === null) {
    fres = res.data;
  }

  return [fres];
}

// 电站总览-新-电站运营收益
export async function queryProfit ({ id }) {
  let fres = {
    yesterday: '',
    month: '',
    total: '',
  };
  const metricCodeArr1 = [
    '020D5014', // 月收益
    '02110132', // 累计收益
  ];
  const metricCodeArr2 = [
    '020D5013', // 日收益
  ];

  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: JSON.stringify([id]), metricCodes: JSON.stringify(metricCodeArr1) });
  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryLatestMetricDatum({ pointIds: pointIdsMap.join(',') });
    const dataHash = data.reduce((acc, item) => {
      if (item) {
        acc[pointIdsHash[item.pointId].metricCode] = {
          ...(item || {}),
          ...pointIdsHash[item.pointId],
        };
      }
      return acc;
    }, {});

    fres = {
      ...fres,
      month: dataHash[metricCodeArr1[0]] ? (+dataHash[metricCodeArr1[0]].value).toFixed(2) : '',
      total: dataHash[metricCodeArr1[1]] ? (+dataHash[metricCodeArr1[1]].value).toFixed(2) : '',
    };
  }


  const [[, pointIdsMap2]] = await queryPointId({ monitorId: JSON.stringify([id]), metricCodes: JSON.stringify(metricCodeArr2) });
  if (pointIdsMap2 && pointIdsMap2.length) {
    const [data2] = await queryRawMetricDatum({
      pointIds: pointIdsMap2.join(','),
      startTime: moment().subtract(1, 'day').startOf('day').valueOf(),
      endTime: moment().endOf('day').valueOf(),
      pointIdsHash
    });

    fres = {
      ...fres,
      yesterday: data2[0].dataList[0] ? (+data2[0].dataList[0].v).toFixed(2) : '',
    };
  }

  return [fres];
}

// 电站总览-新-电站运行状态
export async function queryRunStatus ({ id }) {
  let fres = {
    soc: '',
    yggl: '',
    kcdl: '',
    kfdl: '',
    ljcdl: '',
    ljfdl: '',
  };
  const metricCodeArr = [
    '02110102', // soc
    '02030000', // 有功功率
    '0211013D', // 可充电量
    '0211013E', // 可放电量
    '02110100', // 累计充电量
    '02110101', // 累计放电量
  ];

  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: JSON.stringify([id]), metricCodes: JSON.stringify(metricCodeArr) });
  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryLatestMetricDatum({ pointIds: pointIdsMap.join(',') });
    const dataHash = data.reduce((acc, item) => {
      if (item) {
        acc[pointIdsHash[item.pointId].metricCode] = {
          ...(item || {}),
          ...pointIdsHash[item.pointId],
        };
      }
      return acc;
    }, {});

    fres = {
      soc: dataHash[metricCodeArr[0]] ? (+dataHash[metricCodeArr[0]].value).toFixed(2) : '',
      yggl: dataHash[metricCodeArr[1]] ? (+dataHash[metricCodeArr[1]].value).toFixed(2) : '',
      kcdl: dataHash[metricCodeArr[2]] ? (+dataHash[metricCodeArr[2]].value).toFixed(2) : '',
      kfdl: dataHash[metricCodeArr[3]] ? (+dataHash[metricCodeArr[3]].value).toFixed(2) : '',
      ljcdl: dataHash[metricCodeArr[4]] ? (+dataHash[metricCodeArr[4]].value / 1000).toFixed(2) : '',
      ljfdl: dataHash[metricCodeArr[5]] ? (+dataHash[metricCodeArr[5]].value / 1000).toFixed(2) : '',
    };
  }

  return [fres];
}

// 电站总览-新-逆变器,并网点信息
export async function queryByqBwdInfo ({ id }) {
  let fres = [];
  const res = await getData('storedEnergy', 'getAssetTreeByStationId', { stationId: id });

  if (res.code === null) {
    fres = (res.dataList || []).filter(item => item.displayType === 40003 || item.displayType === 40027);
  }

  return [fres];
}

// 电站总览-新-变压器数据(有功功率, 无功功率)
export async function queryByqData ({ id }) {
  let fres = {
    yggl: '', // 变压器有功功率
    wggl: '', // 变压器无功功率
  };
  const metricCodeArr = [
    '02030000', // 有功功率
    '02040000', // 无功功率
  ];

  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: JSON.stringify([id]), metricCodes: JSON.stringify(metricCodeArr) });
  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryLatestMetricDatum({ pointIds: pointIdsMap.join(',') });
    const dataHash = data.reduce((acc, item) => {
      if (item) {
        acc[pointIdsHash[item.pointId].metricCode] = {
          ...(item || {}),
          ...pointIdsHash[item.pointId],
        };
      }
      return acc;
    }, {});

    fres = {
      yggl: dataHash[metricCodeArr[0]] ? (+dataHash[metricCodeArr[0]].value).toFixed(2) : '',
      wggl: dataHash[metricCodeArr[1]] ? (+dataHash[metricCodeArr[1]].value).toFixed(2) : '',
    };
  }

  return [fres];
}

// 电站总览-新-并网点数据(有功功率, 可充电量, 可放电量)
export async function queryBwdData ({ id }) {
  let fres = {};
  const metricCodeArr = [
    '02030000', // 有功功率
    '0211013D', // 可充电量
    '0211013E', // 可放电量
  ];

  const [[pointIdsHash, pointIdsMap]] = await queryPointId({ monitorId: JSON.stringify(id), metricCodes: JSON.stringify(metricCodeArr) });
  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryLatestMetricDatum({ pointIds: pointIdsMap.join(',') });
    const idHash = data.reduce((acc, item) => {
      if (item) {
        acc[pointIdsHash[item.pointId].monitoredObjectId] = {
          ...(acc[pointIdsHash[item.pointId].monitoredObjectId] || {}),
          [pointIdsHash[item.pointId].metricCode]: {
            ...(item || {}),
            ...pointIdsHash[item.pointId],
          },
        };
      }
      return acc;
    }, {});


    fres = id.reduce((acc, key) => {
      if (idHash[key]) {
        acc[key] = {
          yggl: idHash[key][metricCodeArr[0]] ? (+idHash[key][metricCodeArr[0]].value).toFixed(2) : '',
          kcdl: idHash[key][metricCodeArr[1]] ? (+idHash[key][metricCodeArr[1]].value).toFixed(2) : '',
          kfdl: idHash[key][metricCodeArr[2]] ? (+idHash[key][metricCodeArr[2]].value).toFixed(2) : '',
        };
      }
      return acc;
    }, {});
  }

  return [fres];
}

// 数据监测 - 电池组相关信息(如电压范围, 电芯分组)
export async function queryBatteryGroupDetailInfo ({ monitorId }) {
  let fres = {};
  const res = await getData('storedEnergy', 'getBatteryGroupById', { id: monitorId });

  if (!res.code) {
    fres = (res.data || {});
  }
  return [fres];
}

// 数据监测 - 电池组图表
export async function queryBatteryGroupChart ({ startTime, endTime, dataItem, monitorId, dateType, voltageCellNumber, temperatureCellNumber, batteryGroupItemOrder }) {
  let fres = {};
  const res = await getData('storedEnergy', 'queryBatteryCellRange', { startTime, endTime, dataItem, batteryGroupId: monitorId });
  const fixed = {
    voltage: 3,
    temperature: 1,
    resistance: 2,
    soc: 1,
  };
  if (!res.code) {
    fres = (res.dataList || []).reduce((acc, item) => {
      let highNum = item.highBatteryCellNo && (+item.highBatteryCellNo).toFixed(0);
      let lowNum = item.lowBatteryCellNo && (+item.lowBatteryCellNo).toFixed(0);
      highNum = highNum && `${Math.ceil((batteryGroupItemOrder.indexOf(+highNum) + 1) / (dataItem === 'temperature' ? temperatureCellNumber : voltageCellNumber))}-${(batteryGroupItemOrder.indexOf(+highNum) % (dataItem === 'temperature' ? temperatureCellNumber : voltageCellNumber)) + 1}`;
      lowNum = lowNum && `${Math.ceil((batteryGroupItemOrder.indexOf(+lowNum) + 1) / (dataItem === 'temperature' ? temperatureCellNumber : voltageCellNumber))}-${(batteryGroupItemOrder.indexOf(+lowNum) % (dataItem === 'temperature' ? temperatureCellNumber : voltageCellNumber)) + 1}`;

      acc.high.push({
        value: [item.time, item.high && item.high.toFixed(fixed[dataItem])],
        num: highNum,
        diff: ((item.high && item.high.toFixed(fixed[dataItem])) - (item.low && item.low.toFixed(fixed[dataItem]))).toFixed(fixed[dataItem]),
        dateType,
      });
      acc.low.push({
        value: [item.time, item.low && item.low.toFixed(fixed[dataItem])],
        num: lowNum,
        diff: ((item.high && item.high.toFixed(fixed[dataItem])) - (item.low && item.low.toFixed(fixed[dataItem]))).toFixed(fixed[dataItem]),
        dateType,
      });
      return acc;
    }, { high: [], low: [] });
  }
  return [fres];
}

// 数据监测 - 电池组 - 电芯信息
export async function queryBatteryGroupMonomerInfo ({ dataItem, monitorId }) {
  let fres = [];
  const res = await getData('storedEnergy', 'queryBatteryCellLatestData', { dataItem, batteryGroupId: monitorId });
  const fixed = {
    voltage: 3,
    temperature: 1,
    resistance: 2,
    soc: 1,
  };

  if (!res.code) {
    fres = (res.dataList || []).map(item => ({
      ...item,
      value: item.value && item.value.toFixed(fixed[dataItem]),
    }));
  }
  return [fres];
}

// 数据监测 - 电池组 - 电芯序列
export async function queryBatteryGroupItemOrder ({ monitorId }) {
  let fres = [];
  const res = await getData('storedEnergy', 'listSortedBatteryCellNoByBatteryGroupId', { batteryGroupId: monitorId });

  if (!res.code) {
    fres = res.dataList || [];
  }
  return [fres];
}


export async function queryRunDataByStationIdApi ({ stationIds }) {
  let fres = {};
  const res = await getData('storedEnergy', 'queryRunDataByStationId', { stationIds });

  if (res.code === '200' || res.code === 200) {
    fres = res.data;
  }
  return fres;
}



export async function queryPvStationInfo ({ stationId }) {
  const res = await getData('assetManagement', 'queryStation', { stationId });
  return res
}